import { useEffect, useState } from 'react'
import { Character } from '@/types'
import { hideBoard, resetBubblePositions } from '@/utils'

/**
 * 1. 리덕스를 사용하여 언어 설정 확인
 * 2. fixed 설정을 통해 화면 오른쪽 가장 위에 보여준다.
 */
const BubbleBoard = ({ character }: { character: Character | null }) => {
  const [language, setLanguage] = useState('KO')
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    if (character) {
      setIsDataLoaded(true)
    }
  }, [character])

  return (
    <div
      className="bubble-board hide fixed right-0 w-[32%] overflow-y-scroll bg-[#F9F6F2] border-l-2 border-[#555555]"
      onClick={(e) => e.stopPropagation()}>
      <div className="relative w-full h-full">
        <div className="w-full h-14 flex justify-end items-center space-x-0">
          {character?.link ? (
            <a
              href={character?.link}
              target="_blank"
              rel="noreferrer"
              className="block h-[50px] leading-[50px] px-8 text-xl font-bold rounded-3xl bg-[#F0E5D3] border-[#EDDABF]">
              관련 링크
            </a>
          ) : (
            <></>
          )}
          <div
            className="cursor-pointer text-center align-middle px-5 h-8 flex items-center justify-center"
            onClick={() => {
              hideBoard()
              resetBubblePositions()
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none">
              <line
                x1="1.58579"
                y1="30.7528"
                x2="30.7525"
                y2="1.58611"
                stroke="#555555"
                strokeWidth="4"
              />
              <line
                x1="1.41421"
                y1="1.58579"
                x2="30.5809"
                y2="30.7525"
                stroke="#555555"
                strokeWidth="4"
              />
            </svg>
          </div>
        </div>
        <div className="board-content">
          <span className="font-normal">제목</span>
          <span className="font-extralight">{character?.title?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">연도</span>
          <span className="font-extralight">{character?.year?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article1?.KO}</span>
          <span className="font-extralight">{character?.content1?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article2?.KO}</span>
          <span className="font-extralight">{character?.content2?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article3?.KO}</span>
          <span className="font-extralight">{character?.content3?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article4?.KO}</span>
          <span className="font-extralight">{character?.content4?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article5?.KO}</span>
          <span className="font-extralight">{character?.content5?.KO}</span>
        </div>
      </div>
    </div>
  )
}

export default BubbleBoard
