import React, { useEffect, useState } from 'react'
import { Character } from '@/types'
import {
  hideBoard,
  hideBubbleTitle,
  moveBubbleSiblings,
  resetBubbleTitles,
  SCALE_SIZE,
  showBoard,
  showBubbleTitle,
} from '@/utils'

const Bubble = ({
  character,
  uid,
  order,
  submitUid,
}: {
  character: Character
  uid: string
  order: number
  submitUid: (uid: string) => void
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    if (character) {
      setIsDataLoaded(true)
    }
  }, [character])

  const toggleBubbleSelect = (uid: string) => {
    const bubbles = Array.from(document.querySelectorAll('.bubble-inner'))
    const bubbleYears = Array.from(document.querySelectorAll('.bubble-year'))

    const thisBubble = bubbles.find((item) => item.id == uid)
    resetBubbleLocation(uid, bubbles, bubbleYears)
    resetBubbleTitles()

    if (thisBubble?.classList.contains('selected')) {
      thisBubble?.classList.remove('selected')
      thisBubble?.classList.add('bubble-move-center')
      showBubbleTitle(thisBubble)
      hideBoard()
    } else {
      const order = Number(thisBubble?.getAttribute('data-order'))
      moveBubbleSiblings(order, bubbles, bubbleYears)
      thisBubble?.classList.remove('bubble-move-center')
      thisBubble?.classList.add('selected')
      hideBubbleTitle(thisBubble)
      setTimeout(() => {
        showBoard()
      }, 500)
    }
  }

  const resetBubbleLocation = (
    uid: string,
    bubbles: Element[],
    bubbleYears: Element[]
  ) => {
    bubbles?.forEach((item) => {
      if (item.id != uid) {
        item.classList.remove('selected')
        item.classList.add('bubble-move-center')
      }
      item.classList.remove('bubble-move-left', 'bubble-move-right')
    })

    bubbleYears?.forEach((item) => {
      if (item.id != 'year-'.concat(uid)) {
        item.classList.remove('selected')
        item.classList.add('bubble-move-center')
      }
      item.classList.remove('bubble-move-left', 'bubble-move-right')
    })
  }

  const moveScroll = () => {
    const thisBubble = document.getElementById(uid)
    if (!thisBubble?.classList.contains('selected')) {
      return
    }
    const windowWidth = window.innerWidth
    const bubbleLocation = thisBubble?.getBoundingClientRect().left as number

    const bubbleBox = document.getElementById('homeBubbleBox')
    const bubbleBoxLocation = bubbleBox?.scrollLeft as number
    if (bubbleBox != null && thisBubble?.classList.contains('selected')) {
      const offset = Math.trunc((windowWidth - SCALE_SIZE) / 2)
      // console.log('offset - ' + offset)
      // console.log('bubbleLocation - ' + bubbleLocation)
      // console.log('bubbleBoxLocation - ' + bubbleBoxLocation)

      let move = offset - bubbleLocation
      // console.log('move - ' + move)

      bubbleBox.scrollLeft = bubbleBoxLocation - move
    }
  }

  const setUid = (uid: string) => {
    submitUid(uid)
  }

  const file =
    character.image && character.image.length ? character.image[0].file : null
  return (
    <div className="bubble flex justify-center">
      <div
        className="bubble-inner relative rounded-[100%] w-[400px] h-[400px] overflow-hidden mx-6 z-10 font-gy font-semibold"
        id={uid}
        onClick={(e) => {
          toggleBubbleSelect(uid)
          setTimeout(() => {
            moveScroll()
            setUid(uid)
          }, 350)
          e.stopPropagation()
        }}
        data-order={order}>
        <img
          className="block select-none w-full h-full object-cover"
          src={file?.path}
          alt={file?.name}
        />
        <span className="bubble-title flex items-center justify-center w-full h-full">
          <p
            className="cursor-default select-none text-white text-4xl font-extrabold flex items-center justify-center w-full h-1/6 bg-black bg-opacity-0"
            style={textShadow}>
            {character.title.KO}
          </p>
        </span>
      </div>
    </div>
  )
}

const textShadow = {
  textShadow: '0px 0px 5px black',
}

export default Bubble
